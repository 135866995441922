'use strict';

const React = require('react');


const Logo = () => {
    return (
        <div>
            {/* <div className="blink-logo"></div> */}
            {/* <h1 className="cover-heading">TangTime</h1> */}
        </div>
    );
}


module.exports = Logo;
