'use strict';

const React = require('react');
const PropTypes = require('prop-types');
const { default: clsx } = require('clsx');
const { Grid } = require('@material-ui/core');
const { InView } = require('react-intersection-observer');

const ConferenceModal = require('./ConferenceModal');
const HistoryCard = require('./HistoryCard');
const HistoryTileBox = require('./HistoryTileBox');
const FooterBox = require('./FooterBox');
const URIInput = require('./URIInput');
const config = require('../config');
const utils = require('../utils');

class ReadyBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            targetUri: this.props.missedTargetUri,
            showConferenceModal: false,
            sticky: false,
            height: utils.getWindowHeight() - 50,
            dialedNumber: ''
        };
        this.stickyTopRef = React.createRef();

        // ES6 classes no longer autobind
        this.handleTargetChange = this.handleTargetChange.bind(this);
        this.handleTargetSelect = this.handleTargetSelect.bind(this);
        this.handleAudioCall = this.handleAudioCall.bind(this);
        this.handleVideoCall = this.handleVideoCall.bind(this);
        this.handleChat = this.handleChat.bind(this);
        this.showConferenceModal = this.showConferenceModal.bind(this);
        this.handleConferenceCall = this.handleConferenceCall.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleDialInput = this.handleDialInput.bind(this);
        this.handleDialClick = this.handleDialClick.bind(this);
        this.handleCall = this.handleCall.bind(this);
        this.handleVideo = this.handleVideo.bind(this);
        this.clearDialedNumber = this.clearDialedNumber.bind(this);
        this.conference = '';
    }

    componentDidMount() {
        this.observer = new IntersectionObserver(([e]) => {
            this.setState({ sticky: e.intersectionRatio < 1 });
        }, { threshold: [1] });
        this.observer.observe(this.stickyTopRef.current);
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        this.observer.unobserve(this.stickyTopRef.current);
        window.removeEventListener('resize', this.handleResize);
    }

    getTargetUri() {
        const cleaned = this.state.dialedNumber.replace(/\D/g, '');
        const formattedNumber = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
        const formattedUri = formattedNumber ? `tangtalk-${formattedNumber[1] || ''}-${formattedNumber[2] || ''}-${formattedNumber[3] || ''}`+`@sylk.link` : '';
        console.log('DEBUGGING ------> ', formattedUri);
        return formattedUri;
    }

    handleTargetChange(value) {
        if (value.startsWith(config.publicUrl)) {
            const url = new URL(value);
            if (url.pathname.startsWith('/conference')) {
                this.conference = url.pathname.split('/').pop();
                this.setState({ showConferenceModal: true });
            } else if (url.pathname.startsWith('/call')) {
                value = url.pathname.split('/').pop();
            }
        }
        this.setState({ targetUri: value });
    }

    handleTargetSelect() {
        if (this.props.noConnection) {
            return;
        }
        const targetUri = this.getTargetUri();
        if (targetUri.endsWith(`@${config.defaultConferenceDomain}`)) {
            this.props.startConference(targetUri);
        } else {
            this.props.startCall(targetUri, { audio: true, video: true });
        }
    }

    handleAudioCall(event) {
        event.preventDefault();
        const targetUri = this.getTargetUri();
        if (targetUri.endsWith(`@${config.defaultConferenceDomain}`)) {
            this.props.startConference(targetUri);
        } else {
            this.props.startCall(targetUri, { audio: true, video: false });
        }
    }

    handleVideoCall(event) {
        event.preventDefault();
        const targetUri = this.getTargetUri();
        if (targetUri.endsWith(`@${config.defaultConferenceDomain}`)) {
            this.props.startConference(targetUri);
        } else {
            this.props.startCall(targetUri, { audio: true, video: true });
        }
    }

    handleChat(event) {
        event.preventDefault();
        const targetUri = this.getTargetUri();
        if (!targetUri.endsWith(`@${config.defaultConferenceDomain}`)) {
            this.props.startChat(targetUri);
        }
    }

    showConferenceModal(event) {
        event.preventDefault();
        if (this.state.targetUri.length !== 0) {
            const uri = `${this.state.targetUri.split('@')[0].replace(/[\s()-]/g, '')}@${config.defaultConferenceDomain}`;
            this.handleConferenceCall(uri.toLowerCase());
        } else {
            this.setState({ showConferenceModal: true });
        }
    }

    handleConferenceCall(targetUri, extraOptions) {
        this.setState({ showConferenceModal: false });
        this.conference = '';
        if (targetUri) {
            this.props.startConference(targetUri, extraOptions);
        }
    }

    handleResize() {
        this.setState({ height: utils.getWindowHeight() - 50 });
    }

    handleDialInput(event) {
        const value = event.target.value.replace(/\D/g, ''); // Remove non-digit characters
        if (value.length <= 10) {
            this.setState({ dialedNumber: value });
        }
    }

    handleDialClick(value) {
        this.setState((prevState) => {
            const newValue = prevState.dialedNumber + value;
            return {
                dialedNumber: newValue.length <= 10 ? newValue : prevState.dialedNumber
            };
        });
    }

    handleCall() {
        this.setState({ targetUri: this.state.dialedNumber }, () => {
            this.handleAudioCall(new Event('call'));
        });
    }

    handleVideo() {
        this.setState({ targetUri: this.state.dialedNumber }, () => {
            this.handleVideoCall(new Event('video'));
        });
    }

    clearDialedNumber() {
        this.setState({ dialedNumber: '' });
    }

    formatDialedNumber(number) {
        const cleaned = ('' + number).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
        if (match) {
            return [
                match[1] && `(${match[1]}`,
                match[2] && `${match[1] ? ') ' : ''}${match[2]}`,
                match[3] && `${match[1] || match[2] ? '-' : ''}${match[3]}`
            ].filter(Boolean).join('');
        }
        return number;
    }

    render() {
        const classes = clsx({
            'btn': true,
            'btn-round-big': true,
            'btn-success': this.state.targetUri.length !== 0,
            'btn-default': this.state.targetUri.length === 0
        });

        const stickyClasses = clsx({
            'sticky-wrapper': true,
            'sticky': this.state.sticky
        });

        const dialPadNumbers = [
            { num: '1', letters: '' },
            { num: '2', letters: 'ABC' },
            { num: '3', letters: 'DEF' },
            { num: '4', letters: 'GHI' },
            { num: '5', letters: 'JKL' },
            { num: '6', letters: 'MNO' },
            { num: '7', letters: 'PQRS' },
            { num: '8', letters: 'TUV' },
            { num: '9', letters: 'WXYZ' }
        ];

        return (
            <div>
                <div className="cover-container">
                    <div className="inner cover scroll" style={{ height: this.state.height, alignItems: 'flex-start' }}>
                        <div className={stickyClasses} ref={this.stickyTopRef}>
                            <div className="form-dial">
                                <div className="dialpad-display" style={{ fontSize: '24px', height: '45px', position: 'relative', background:'#00000080', paddingTop: '5px' }}>
                                    {this.formatDialedNumber(this.state.dialedNumber)}
                                    {this.state.dialedNumber && (
                                        <button
                                            onClick={this.clearDialedNumber}
                                            style={{
                                                position: 'absolute',
                                                right: '10px',
                                                top: '10px',
                                                background: 'none',
                                                border: 'none',
                                                fontSize: '20px',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            &times;
                                        </button>
                                    )}
                                </div>
                                <input
                                    type="text"
                                    value={this.state.dialedNumber}
                                    onChange={this.handleDialInput}
                                    style={{ display: 'none' }}
                                />
                                <div className="dialpad" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', height: 'calc(100vh - 95px)' }}>
                                    {dialPadNumbers.map(({ num, letters }) => (
                                        <button
                                            key={num}
                                            onClick={() => this.handleDialClick(num)}
                                            className="dialpad-button"
                                            style={{
                                                width: '33.33%',
                                                height: 'calc(25% - 0px)',
                                                fontSize: '18px',
                                                margin: '0px',
                                                borderRadius: '0px',
                                                backgroundColor: '#f0f0f0',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                border: '1px solid #00000080',
                                                background: '#000000b3',
                                                color: '#fff'
                                            }}
                                        >
                                            <span>{num}</span>
                                            <span style={{ fontSize: '9px' }}>{letters}</span>
                                        </button>
                                    ))}
                                    <button
                                        onClick={this.handleCall}
                                        className="dialpad-button"
                                        style={{
                                            width: '33.33%',
                                            height: 'calc(25% - 0px)',
                                            fontSize: '18px',
                                            margin: '0px',
                                            borderRadius: '0px',
                                            background: '#ff8700',
                                            color: '#fff',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <i className="fa fa-phone" style={{ fontSize: '24px', background: '#ff8700'}}></i>
                                    </button>
                                    <button
                                        onClick={() => this.handleDialClick('0')}
                                        className="dialpad-button"
                                        style={{
                                            width: '33.33%',
                                            height: 'calc(25% - 0px)',
                                            fontSize: '18px',
                                            margin: '0px',
                                            borderRadius: '0px',
                                            backgroundColor: '#f0f0f0',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            border: '1px solid #333',
                                            border: '1px solid #00000080',
                                            background: '#000000b3',
                                            color: '#fff'
                                        }}
                                    >
                                        <span>0</span>
                                        <span style={{ fontSize: '9px' }}>+</span>
                                    </button>
                                    <button
                                        onClick={this.handleVideo}
                                        className="dialpad-button"
                                        style={{
                                            width: '33.33%',
                                            height: 'calc(25% - 0px)',
                                            fontSize: '18px',
                                            margin: '0px',
                                            borderRadius: '0px',
                                            backgroundColor: '#ff8700',
                                            color: '#fff',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <i className="fa fa-video-camera" style={{ fontSize: '24px', backgroundColor: '#ff8700' }}></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="extra-shadow"></div>
                        <HistoryTileBox>
                            {this.props.serverHistory.filter(historyItem => historyItem.remoteParty.startsWith(this.state.targetUri)).map((historyItem, idx) =>
                            (
                                <Grid item md={4} sm={6} xs={12} key={idx}>
                                    <InView threshold={0.01}>
                                        {({ inView, ref, entry }) => (
                                            <div
                                                ref={ref}
                                                className={inView ? 'card-visible animated bounceIn' : 'card-hidden'}
                                            >
                                                <HistoryCard
                                                    historyItem={historyItem}
                                                    setTargetUri={this.handleTargetChange}
                                                    startVideoCall={this.handleVideoCall}
                                                    startAudioCall={this.handleAudioCall}
                                                    startChat={this.handleChat}
                                                    noConnection={this.props.noConnection}
                                                />
                                            </div>
                                        )}
                                    </InView>
                                </Grid>
                            )
                            )}
                        </HistoryTileBox>
                        <FooterBox />
                    </div>
                </div>
                <ConferenceModal
                    show={this.state.showConferenceModal}
                    handleConferenceCall={this.handleConferenceCall}
                    conference={this.conference}
                />
            </div>
        );
    }
}

ReadyBox.propTypes = {
    account: PropTypes.object.isRequired,
    startCall: PropTypes.func.isRequired,
    startConference: PropTypes.func.isRequired,
    startChat: PropTypes.func.isRequired,
    missedTargetUri: PropTypes.string,
    history: PropTypes.array,
    serverHistory: PropTypes.array,
    noConnection: PropTypes.bool
};

module.exports = ReadyBox;
